import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export abstract class RestService {
  protected readonly apiBaseUrls = environment.apiBaseUrls;
  protected readonly abstract baseUrl: string;

  constructor(protected httpClient: HttpClient) {}

  private queryParamsToString(params: {}): string {
    let queryParamStrings: string[] = [];
    Object.keys(params).forEach(key => {
      queryParamStrings.push(`${key}${params[key] !== '' ? '='+ params[key] : ''}`)
    })
    
    return queryParamStrings.length ?
      `?${queryParamStrings.join('&')}` :
      '';
  }
  
  protected get(relativeUrl: string, queryParams?: {}, requestOptions?: {}): Observable<any> {
    let urlComponents = [
      this.baseUrl,
      relativeUrl,
      queryParams ? this.queryParamsToString(queryParams): undefined
    ].filter(Boolean)

    console.log("GET request sent:", urlComponents.join('/'))
    return this.httpClient.get(urlComponents.join('/'), requestOptions);
  }

  protected getExportUrl(relativeUrl: string, queryParams?: {}, requestOptions?: {}): string {
    let urlComponents = [
      this.baseUrl,
      relativeUrl,
      queryParams ? this.queryParamsToString(queryParams): undefined
    ].filter(Boolean)

    console.log("Export GET request sent:", urlComponents.join('/'))
    return urlComponents.join('/');
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plot-view',
  templateUrl: './plot-view.component.html',
  styleUrls: ['./plot-view.component.scss']
})
export class PlotViewComponent {
  private _data;

  @Input()
  set data(data: {}[]) {
    this._data = data;
  }
  
  get data(): {}[] { return this._data }
  
  @Input() layout: {};
  @Input() revision: string;
  @Input() debug: string;
  @Input() useResizeHandler: boolean;
  @Input() config: {};
  @Input() style: {};
}

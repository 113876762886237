import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Component } from '@angular/core';

import { ApiGatewayService } from '../../services/api-gateway.service';
import { PlotContainer } from '../../models/plot-container.abstract';
import { TransformDataService } from '../../services/transform-data.service';
import { pluck, toArray, map, tap, reduce, debounceTime } from 'rxjs/operators';
import { DataStream } from 'src/app/data-stream';
import { QueryParamBuilder } from '@ngqp/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss']
  
})
export class DemandComponent extends PlotContainer {
  public datasetType: string[];
  public identifierParam: string;
  public layout: {};
  public defaultDataset: string;
  public startMonth: string;
  public endMonth: string; 
  public useResizeHandler: boolean = true;
  public config: {} = {responsive: true};

  constructor(
    private apiGateway: ApiGatewayService,
    protected dataTransformer: TransformDataService,
    protected readonly route: ActivatedRoute,
    protected queryParamBuilder: QueryParamBuilder,
    protected router: Router
    ) { super(queryParamBuilder); }
    
    ngOnInit(): void {
    let gisIds: {};
    let polygonCodes: {};
    let traceId: string;
    let dataStream: DataStream<any>;
    let dataStream$: Observable<any>;
    let requestQueryParameters: {};

    requestQueryParameters = this.getRequestQueryParameters();
    this.setDefaultValues();
    
    this.paramGroupValueChanges$.subscribe(selection => {
      traceId = selection.demandType.toLowerCase();
      if (traceId === "consumption by category") traceId = "consumptionBreakdown";
      if (traceId === "commercial growth") traceId = "commercialGrowth";
      this.layout = this.layoutDefinitions[traceId];   
      this.dataTraces = [];
      
      dataStream = this.getDataStream(traceId, requestQueryParameters);
      
      //Set the manual x-axis range by finding the latest date based on the data
      if (this.layout['setRange']) {
        this.setXAxisRange(dataStream)
      }
       
      this.addTrace(traceId, this.traceDefinitions[traceId], dataStream);
      
      dataStream$ = dataStream.stream;
      dataStream$.pipe(toArray()).subscribe(data => {
        this.setAnnotations(data, traceId)   
      })
      
    });
  }

  datasetDefinitions = {
    DMA : {
      selectValues: ['Commercial Growth', 'Consumption','Consumption by Category', 'Non-Revenue Water', 'Population', 'Supply'],
      defaultDataset: 'supply',
      identifier: 'polygonCodes'
    },
    'Water Supply Zone': {
      selectValues: ['Commercial Growth', 'Consumption', 'Consumption by Category', 'Population'],
      defaultDataset: 'population',
      identifier: 'gisid'
    },
    'Wastewater Local Catchment Area': {
      selectValues: ['Commercial Growth', 'Consumption', 'Consumption by Category', 'Population'],
      defaultDataset: 'population',
      identifier: 'gisid'
    },
    default : {
      selectValues: ['Commercial Growth', 'Consumption', 'Consumption by Category', 'Population'],
      defaultDataset: 'population',
      identifier: 'gisid'
    }
  }

  traceDefinitions = {
    population: {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        transforms: [{
          type: 'groupby',
          groups: []
        }]
      },
      adapter: {
        cal_year: this.pushValue('x'),
        value: this.pushValue('y'),
        polygon_description: (value, trace) => {
          trace.text.push(value);
          trace.transforms[0].groups.push(value);
        }
      },
      dataValidation : {
        value: {
          description: 'must be an integer > 0',
          clean: row => Number(row.population) ? Math.ceil(row.population) : undefined, 
          isValid: value => value >= 1 && Number.isInteger(value)
        },
        polygon_description: {
          description: 'must exist',
          clean: row => row.dz_name && row.dz_name.length ? row.dz_name.toString() : `missing dz_name (gis_id=${row.gis_id})`, 
          isValid: Boolean,
        },
        cal_year: {
          description: 'must exist',
          isValid: Boolean,
        }
      }
    },
    consumption: {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'y+text',
        transforms: [
          {
            type: 'aggregate',
            groups: [],
            aggregations: [
              {target: 'y', func: 'sum', enabled: true}
            ]
          },
          {
            type: 'groupby',
            groups: []
          }
        ]
      },
      adapter: {
        cal_year: (value, trace) => {
          trace.x.push(value)
          trace.transforms[0].groups.push(value)
        },

        value: this.pushValue('y'),
        polygon_description: (value, trace) => {
          trace.text.push('m3 ' + value);
          trace.transforms[1].groups.push(value);
        }
      },
      dataValidation : {
        cal_month_no : {
          description: 'must have a 0 in front if single digit month',
          clean: row => "0" + row.cal_month_no,
          isValid: value => value.toString().length == 2
        },
        cal_year : {
          description: 'combining with cal_month_no to get a YYYY-MM format.',
          clean: row => Number(row.cal_year) && Number(row.cal_month_no) ? row.cal_year + "-" + row.cal_month_no : undefined,
          isValid: value => value.toString().includes("-")
        },
        value : {
          description: 'must exist',
          clean: row => Number(row.value) ? Math.round(row.value) : undefined, 
          isValid: value => value >= 1 && Number.isInteger(value)
        }
      }
    },
    consumptionBreakdown: {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'y+text',
        transforms: [
          {
            type: 'aggregate',
            groups: [],
            aggregations: [
              {target: 'y', func: 'sum', enabled: true}
            ]
          },
          {
            type: 'groupby',
            groups: []
          }
        ],
        hovertemplate: "%{y}m3"
      },
      adapter: {
        cal_year: (value, trace) => {
          trace.x.push(value)
          trace.transforms[0].groups.push(value)
        },
        value: this.pushValue('y'),
        consumption_category: (value, trace) => {
          trace.transforms[1].groups.push(value);
          trace.text.push(value);
        }
      },
      dataValidation : {
        cal_month_no : {
          description: 'must have a 0 in front if single digit month',
          clean: row => "0" + row.cal_month_no,
          isValid: value => value.toString().length == 2
        },
        cal_year : {
          description: 'combining with cal_month_no to get a YYYY-MM format.',
          clean: row => Number(row.cal_year) && Number(row.cal_month_no) ? row.cal_year + "-" + row.cal_month_no : undefined,
          isValid: value => value.toString().includes("-")
        },
        value : {
          description: 'must exist',
          clean: row => Number(row.value) ? Math.round(row.value) : undefined, 
          isValid: value => value >= 1 && Number.isInteger(value)
        }
      }
    },
    supply: {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'y+text',
        transforms: [
          {
            type: 'aggregate',
            groups: [],
            aggregations: [
              {target: 'y', func: 'sum', enabled: true}
            ]
          },
          {
            type: 'groupby',
            groups: []
          }
        ]
      },
      adapter: {
        cal_year: (value, trace) => {
          trace.x.push(value)
          trace.transforms[0].groups.push(value)
        },

        value: this.pushValue('y'),
        polygon_description: (value, trace) => {
          trace.text.push('m3 ' + value);
          trace.transforms[1].groups.push(value);
        }
      },
      dataValidation : {
        cal_month_no : {
          description: 'must have a 0 in front if single digit month',
          clean: row => "0" + row.cal_month_no,
          isValid: value => value.toString().length == 2
        },
        cal_year : {
          description: 'combining with cal_month_no to get a YYYY-MM format.',
          clean: row => Number(row.cal_year) && Number(row.cal_month_no) ? row.cal_year + "-" + row.cal_month_no : undefined,
          isValid: value => value.toString().includes("-")
        },
        value : {
          description: 'must exist',
          clean: row => Number(row.value) ? Math.round(row.value) : undefined, 
          isValid: value => value >= 1 && Number.isInteger(value)
        }
      }
    },
    "non-revenue water": {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        hoverinfo: 'y+text',
        transforms: [
          {
            type: 'aggregate',
            groups: [],
            aggregations: [
              {target: 'y', func: 'sum', enabled: true}
            ]
          },
          {
            type: 'groupby',
            groups: []
          }
        ]
      },
      adapter: {
        cal_year: (value, trace) => {
          trace.x.push(value)
          trace.transforms[0].groups.push(value)
        },
        value: this.pushValue('y'),
        polygon_description: (value, trace) => {
          trace.text.push('m3 ' + value);
          trace.transforms[1].groups.push(value);
        }
      },
      dataValidation : {
        cal_month_no : {
          description: 'must have a 0 in front if single digit month',
          clean: row => "0" + row.cal_month_no,
          isValid: value => value.toString().length == 2
        },
        cal_year : {
          description: 'combining with cal_month_no to get a YYYY-MM format.',
          clean: row => Number(row.cal_year) && Number(row.cal_month_no) ? row.cal_year + "-" + row.cal_month_no : undefined,
          isValid: value => value.toString().includes("-")
        },
        value : {
          description: 'must exist',
          clean: row => Number(row.value) ? Math.round(row.value) : undefined, 
          isValid: value => Number.isInteger(value)
        }
      }
    },
    commercialGrowth: {
      trace: {
        type: 'bar',
        x: [],
        y: [],
        text: [],
        transforms: [{
          type: 'groupby',
          groups: []
        }]
      },
      adapter: {
        cal_year: this.pushValue('x'),
        value: this.pushValue('y'),
        polygon_description: (value, trace) => {
          trace.text.push(value);
          trace.transforms[0].groups.push(value);
        }
      },
      dataValidation : {
        value: {
          description: 'must be an integer > 0',
          clean: row => Number(row.population) ? Math.ceil(row.population) : undefined, 
          isValid: value => value >= 1 && Number.isInteger(value)
        },
        polygon_description: {
          description: 'must exist',
          clean: row => row.dz_name && row.dz_name.length ? row.dz_name.toString() : `missing dz_name (gis_id=${row.gis_id})`, 
          isValid: Boolean,
        },
        cal_year: {
          description: 'must exist',
          isValid: Boolean,
        }
      }
    }
  }

  layoutDefinitions = {
    consumption: {
      title: {
        text:'Historical Consumption'
      },
      xaxis: {
        title: {
          text: 'Month'
        },
        type: 'date',
        dtick: "M1",
        automargin: true        
      },
      yaxis: {
        title: {
          text: 'Consumption (m3)',
        },
        hoverformat: ',d'
      },
      barmode: 'stack',
      hovermode: 'closest',
      setRange: true
    },
    population: {
      title: {
        text:'Population Forecast'
      },
      xaxis: {
        title: {
          text: 'Year'
        },
        tick0: 2018,
        dtick: 5
      },
      yaxis: {
        title: {
          text: 'Population'
        },
        hoverformat: ',d'
      },
      barmode: 'stack',
      hovermode: 'closest',
      setRange: false
    },
    supply: {
      title: {
        text: 'Historical Supply'
      },
      xaxis: {
        title: {
          text: 'Month'
        },
        type: 'date',
        dtick: "M1",
        automargin: true
      },
      yaxis: {
        title: {
          text: 'Supply (m3)'
        },
        hoverformat: ',d'
      },
      barmode: 'stack',
      hovermode: 'closest',
      setRange: true
    },
    "non-revenue water": {
      title: {
        text: 'Non-Revenue Water per DMA'
      },
      xaxis: {
        title: {
          text: 'Month'
        },
        type: 'date',
        dtick: "M1",
        automargin: true
      },
      yaxis: {
        title: {
          text: 'Non-Revenue Water (m3)'
        },
        hoverformat: ',d'
      },
      barmode: 'relative',
      hovermode: 'closest',
      setRange: true
    },
    "consumptionBreakdown": {
      title: {
        text:'Consumption By Category'
      },
      xaxis: {
        title: {
          text: 'Month'
        },
        type: 'date',
        dtick: "M1",
        automargin: true        
      },
      yaxis: {
        title: {
          text: 'Consumption (m3)',
        },
        hoverformat: ',d'
      },
      barmode: 'stack',
      hovermode: 'closest',
      setRange: true
    },
    commercialGrowth: {
      title: {
        text:'Commercial Growth'
      },
      xaxis: {
        title: {
          text: 'Year'
        },
        tick0: 2018,
        dtick: 5
      },
      yaxis: {
        title: {
          text: 'Population'
        },
        hoverformat: ',d'
      },
      barmode: 'stack',
      hovermode: 'closest',
      setRange: false
    }
  }
    
  setAnnotations(data, traceId){
    this.layoutDefinitions[traceId].annotations = this.getAnnotations(data);
  }

  getAnnotations(data) {
    let uniqueDates: string[] = Array.from(new Set(data.map(row => row.cal_year )));
    let sumConsumptionOfYears = this.getSumValueOfYear(data, uniqueDates);
    let yAnnotationValue = this.getYValueForAnnotation(data, uniqueDates);

    let annotations: {}[] = sumConsumptionOfYears.map(sum => {
      let index = sumConsumptionOfYears.indexOf(sum);
      sum = Math.ceil(sum)
      return {
        x:uniqueDates[index],
        y:yAnnotationValue[index],
        text: sum,
        //textangle:-60,
        xanchor: 'center',
        yanchor: 'bottom',
        showarrow: false,
        automargin: true
      }
    });

    return annotations;
  }

  getSumValueOfYear(data, uniqueYears) {
    return uniqueYears.map(year => {
      let valuesFromYears = data.filter(row => row.cal_year == year);
      return Object.keys(valuesFromYears).map(key => valuesFromYears[key].value).reduce((acc, curr) => acc + curr,0);
    });
  }

  getYValueForAnnotation(data, uniqueYears) {
    return uniqueYears.map(year => {
      let valuesFromYears = data.filter(row => row.cal_year == year);
      return Object.keys(valuesFromYears).map(key => {
        return valuesFromYears[key].value
      }).reduce((acc, curr) => {
        if (curr > 0) {
          return acc + curr;
        } else {
          return acc + 0;
        } 
      }, 0);
    });
  }

  exportHandler(event) {
    let queryParamMap: ParamMap = this.route.snapshot.queryParamMap;
    let traceId: string = queryParamMap.get('demandType') ? queryParamMap.get('demandType').toLowerCase() : this.defaultDataset;
    let polygonName: string = queryParamMap.get('polygonName');
    let url: string;

    switch (traceId) {
      case "consumption by category":
        traceId = "consumption";
        break;
      case "non-revenue water":
        traceId = "nonRevenueWater";
        break;
      case "commercial growth":
        traceId = "commercialGrowth";
        break;
    };

    if (this.identifierParam == "gisid") {
      let gisIds = queryParamMap.getAll('gisid').toString();
      url = this.apiGateway.getExportUrlByGisId(traceId, { gisid: gisIds as string, polygonName: polygonName as string, export: '' })
    } else if (this.identifierParam == "polygonCodes") {
      let polygonCodes = queryParamMap.getAll('polygonCodes').toString();
      url = this.apiGateway.getExportUrlByPolygonCode(traceId, { polygonCodes: polygonCodes as string, polygonName: polygonName as string, export: ''})
    }
    
    window.location.href= url;
  }

  setDefaultValues(){
    let polygonName: string = this.route.snapshot.queryParamMap.get("polygonName");
    if (polygonName in this.datasetDefinitions === false) polygonName = "default"

    this.defaultDataset = this.datasetDefinitions[polygonName].defaultDataset;
    this.datasetType = this.datasetDefinitions[polygonName].selectValues;
    this.identifierParam = this.datasetDefinitions[polygonName].identifier;
    this.registerQueryParam<string>("demandType", this.queryParamBuilder.stringParam, null, { emptyOn: this.defaultDataset});
  }

  moreInfoHandler(event) {
    let polygonName: string;
    let demandType: string;

    try {
      polygonName = this.route.snapshot.queryParamMap.get("polygonName");
      demandType = this.route.snapshot.queryParamMap.get('demandType').toLowerCase();
    } catch {
      demandType = this.datasetDefinitions[polygonName].defaultDataset;
      console.log(`Demand type not defined, defaulting to ${demandType}`);
    }

    if (demandType === "consumption by category") demandType = "consumption";
    
    switch (demandType) {
      case "consumption": 
        window.open("https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/381780126/Geospatial+Consumption");
        break;
      case "supply":
        window.open("https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/381846667/Geospatial+Supply");
        break;
      case "population":
        window.open("https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/208438170/Population+Modelling");
        break;
      case "non-revenue water":
        window.open("https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/382337388/Non-Revenue+Water");  
        break;
      case "commercial growth":
        window.open("https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/208438170/Population+Modelling");
        break;
    }
  }

  getDataStream(traceId: string, requestQueryParameters: {}) {
    let dataStream: DataStream<any>;
    let identifiers: {};
    
    if (Object.keys(requestQueryParameters).includes('polygonCodes')) {
      identifiers = requestQueryParameters['polygonCodes'];
      if (traceId == "non-revenue water") {
        dataStream = new DataStream<any>(this.apiGateway.getNonRevenueWater({polygonCodes: identifiers as string}), this.traceDefinitions[traceId].dataValidation);
      } else {
        console.log("Trace Id:", traceId)
        dataStream = new DataStream<any>(this.apiGateway.getDemandByPolygonCode(traceId, { polygonCodes: identifiers as string, polygonName: requestQueryParameters['polygonName'] as string }), this.traceDefinitions[traceId].dataValidation);
      }
    }
    else {
      identifiers = requestQueryParameters['gisid'];
      dataStream = new DataStream<any>(this.apiGateway.getDemandbyGisId(traceId, { gisid: identifiers as string, polygonName: requestQueryParameters['polygonName'] as string }), this.traceDefinitions[traceId].dataValidation);
    }  
    
    return dataStream;
  }

  setXAxisRange(dataStream: DataStream<any>) {
    this.layout['xaxis']['range'] = [0,0];
    dataStream.stream.pipe(
      map(element => new Date(element.cal_year)),
      toArray()
    ).subscribe(data => {
      this.endMonth = moment(Math.max.apply(null, data)).add(14, 'days').format('YYYY-MM-DD');
      this.startMonth = moment(this.endMonth).subtract(1, 'year').format('YYYY-MM-DD');
      this.layout['xaxis']['range'] = [this.startMonth, this.endMonth]
    });
  }

  getRequestQueryParameters() {
    let queryParamsSnapshot:{} = this.route.snapshot.queryParams;
    let queryParams = {};

    Object.keys(queryParamsSnapshot)
      .filter(key => key !== 'demandType')
      .forEach(key => queryParams[key] = queryParamsSnapshot[key]);
    
    return queryParams;
  }
}

import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { Observable, of, zip} from 'rxjs';
import { QueryParamBuilder } from '@ngqp/core';
import * as moment from 'moment';
import { tap, map, flatMap, toArray, switchMap, groupBy, reduce, pluck } from 'rxjs/operators';

import { ApiGatewayService, TimeSeriesData, TimeSeriesMetadata } from 'src/app/services/api-gateway.service';
import { DataStream } from 'src/app/data-stream';
import { PlotContainer } from 'src/app/models/plot-container.abstract';

// for testing without API access
// import { MockDataService } from 'src/app/services/mock-data.service';

@Component({
  selector: 'app-timeseries',
  templateUrl: './timeseries.component.html',
	styleUrls: ['./timeseries.component.css']
})
export class TimeseriesComponent extends PlotContainer {
  public rainfallMetadata$: Observable<any>;
  public metadata$: Observable<{}>;
  public minCalendarPickerDate: Date;
  public maxCalendarPickerDate: Date;
  public useResizeHandler: boolean = true;
  public config: {} = {responsive: true};
  public style: {} = {position: 'absolute', width: '99%', height: '99%'};
  
  constructor(
    private apiGateway: ApiGatewayService,
    protected queryParamBuilder: QueryParamBuilder,
		protected route: ActivatedRoute
  ) { super(queryParamBuilder) }

  playAudio() {
    var x = <HTMLAudioElement> document.getElementById("myAudio"); 
    x.play();
  }
  pauseAudio() {
    var x = <HTMLAudioElement> document.getElementById("myAudio"); 
    x.pause();
  }
  ngOnInit(): void {
    let dataTags: string;
    let startDate: {};
    let endDate: {}
    let dataStream: DataStream<any>;
    let metadata$: any;
    
    this.route.queryParamMap.pipe(pluck('params', 'startDate')).subscribe(date => {
      startDate = date;
    });
    
    this.route.queryParamMap.pipe(pluck('params', 'endDate')).subscribe(date => {
      endDate = date;
    });

    this.route.queryParamMap.pipe(pluck('params', 'dataTags')).subscribe(tags => {
      let _tags: string = tags.toString();
      let tagsArray = _tags.split(",");
      let gisIds = "";
      let dataTagMap: {} = {};
      tagsArray.forEach(tag => {
        let temp = tag.split(":");
        try {
          dataTagMap[temp[0]] = temp[1];
          gisIds += temp[1] + ",";
        } catch (e) {
          console.log(e)
        }
      }); 

      if (gisIds.charAt(gisIds.length-1) == ",") gisIds = gisIds.substring(0, gisIds.length-1);

      this.apiGateway.getTimeseriesMetadata({"gisid":gisIds}).pipe(toArray()).subscribe(result=> {
        let uniqueUnits: {} = this.getUniqueUnits(result);
        let rainfallExists: Boolean = this.checkRainfall(result); 
        this.setUpYAxes(uniqueUnits, rainfallExists);

        result.forEach(metadata => {
          if (Object.keys(dataTagMap).includes(metadata.data_tag)) {
            dataStream = new DataStream<any>(this.apiGateway.getTimeseriesData({ dataTags: metadata.data_tag as string, startDate: startDate as string, endDate: endDate as string}), this.dataValidation);

            let unit = metadata.data_category.toLowerCase() == "rainfall" ? this.traceDefinitions["rainfall"] : this.traceDefinitions[metadata.units];
            this.addTrace(metadata.data_description, unit, dataStream);
          }
        })
      })

    });

  }

  getUniqueUnits(results: {}[]): {} {
    let uniqueUnits: {} = {};
    let dataTagsAndGisIds = this.route.snapshot.queryParamMap.get("dataTags").split(",");
    let dataTags = dataTagsAndGisIds.map(x => x.split(":")[0])

    results.forEach(row => {
      if (dataTags.includes(row["data_tag"])) {
        this.createUniqueUnitObject(uniqueUnits, row);
      }
    });
    return uniqueUnits;
  }

  createUniqueUnitObject(uniqueUnits, row): void {
    let rowUnit = row["units"];
    let rowDataCategory = row["data_category"];

    if (rowUnit in uniqueUnits) {
      if (!uniqueUnits[rowUnit].includes(rowDataCategory)) 
        uniqueUnits[rowUnit].push(rowDataCategory);    
    } else {
      uniqueUnits[rowUnit] = [rowDataCategory];
    }
  }

  checkRainfall(results: {}[]): Boolean {
    let found = false;
    results.forEach(row => {
      if(row["data_category"] !== undefined && row["data_category"].toLowerCase() === "rainfall") found = true
    });
    
    return found;
  }

  setUpYAxes(uniqueUnits: {}, rainfallExists: Boolean): void {
    let axesCount: number = 1;
    let rowCount: number = Object.keys(uniqueUnits).length;
    this.layout.grid.rows = rowCount;

    if (rainfallExists) {
      this.setUpRainfall(uniqueUnits);
      axesCount += 1;
    };

    Object.keys(uniqueUnits).forEach(key => {
      let yAxisKey = "yaxis" + axesCount;
      this.traceDefinitions[key].trace["yaxis"] = "y"+axesCount;
      
      if (axesCount == 1) 
        yAxisKey = "yaxis";
      this.layout[yAxisKey] = {title: key}
      this.layout[yAxisKey]["hoverformat"] = ".2f";
      axesCount += 1;
    });

    console.log("Layout:", this.layout)
  }

  setUpRainfall(uniqueUnits): void {
    Object.keys(uniqueUnits).forEach(key => {
      if (uniqueUnits[key].includes("Rainfall") && uniqueUnits[key].length == 1) {
        delete uniqueUnits[key];
      }
    })
    this.layout.yaxis['autorange'] = 'reversed';
    this.layout.yaxis['title'] = 'Rainfall (mm)';
  } 

  traceDefinitions = {
    default: {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    kPa: {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    m3: {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    'm3/h': {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    'm3/d': {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    'm3/day': {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    m: {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp:this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    '%': {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    'l/s': {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    Alarm: {
      trace: {
        type: 'scattergl',
        mode: 'markers',
        x: [],
        y: [],
        text: [],
        transforms: [{
          type: 'filter',
          target: 'y',
          operation: '>',
          value: 0
        }]
      },
      adapter: {
        time_stamp: this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    },
    rainfall: {
      trace: {
        type: 'scattergl',
        mode: 'lines',
        x: [],
        y: [],
        text: []
      },
      adapter: {
        time_stamp:this.pushValue('x'),
        value: this.pushValue('y'),
        trace_name: this.pushValue('text')
      }
    }
  }

	layout = {
    grid: {
      rows: 1,
      columns: 1,
      pattern: 'coupled',
      roworder:'top to bottom'
    },
    xaxis: {
      type: 'date',
      autorange: true,
      range: []
    },
    yaxis: {
      hoverformat: '.2f',
    },
    legend: {
      yanchor: "center",
      orientation: "h"
    },
    margin: {
      b: 30,
      t: 20
    },
    autosize: true
	}

	dataValidation = {
		/* time_stamp: {
			description: 'must be in date format',
			clean: row => moment(row.time_stamp, 'YYYYMMDDHHmmss').toDate(), 
			isValid: value => value instanceof Date
		} */
	};
}

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PapaParseModule } from 'ngx-papaparse';
import { QueryParamModule } from '@ngqp/core';
import { PlotlyViaWindowModule  } from 'angular-plotly.js';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DemandComponent } from './components/demand/demand.component';
import { MaterialModule } from './material.module';
import { PlotViewComponent } from './components/plot-view/plot-view.component';
import { TimeseriesComponent } from './components/timeseries/timeseries.component';

@NgModule({
  declarations: [
    AppComponent,
    DemandComponent,
    PlotViewComponent,
    TimeseriesComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    PapaParseModule,
    PlotlyViaWindowModule,
    QueryParamModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { ParamMap } from '@angular/router';
import { map, flatMap } from 'rxjs/operators';

import { RestService } from '../models/rest-service.abstract';

export interface AnimalCount {
  time: number,
  type: 'cat' | 'dog',
  count: number
}

export interface Consumption {
  gis_id: number,
  description: string,
  cal_year: number,
  cal_month_no: number,
  value: number,
  date: number
}

@Injectable({
  providedIn: 'root'
})
export class MockDataService extends RestService {
  baseUrl = "assets/mock-data";
  constructor(
    protected httpClient: HttpClient,
    private papa: Papa
    ) { super(httpClient); }

  private parseCSVError = text => { 
    throw Error(`error parsing csv:
      ${text}`) 
  }

  getCatsAndDogs(queryParams?: ParamMap): Observable<AnimalCount[]> {
    return this.get('catsAndDogs.csv', queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            })
      );
  }

  getMockConsumption(queryParams: { gisid: string }): Observable<any> {
    return this.get('consumption_data.csv', queryParams, { responseType: 'text' })
    .pipe(
      map(text => {
            const result = this.papa.parse(text, {
              dynamicTyping: true,
              header: true,
              skipEmptyLines: true});
            if (result.errors.length) { this.parseCSVError(text); }
            return result.data;
          }),
      flatMap(result => result)
    );
  }

  getMockSupply(): Observable<any> {
    return this.get('Sample BSP Supply Data Extract.csv', null, { responseType: 'text' })
    .pipe(
      map(text => {
            const result = this.papa.parse(text, {
              dynamicTyping: true,
              header: true,
              skipEmptyLines: true});
            if (result.errors.length) { this.parseCSVError(text); }
            return result.data;
          }),
      flatMap(result => result)
    );
  }

  getTimeseriesMetadata(queryParams?: ParamMap): Observable<any> {
    return this.get('ts_metadata.csv', queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            }),
        flatMap(result => result)
      );
  }

  getTimeseriesData(gisid: string, data_tag: string): Observable<any> {
    return this.get('ts_data.csv', null, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            }),
        flatMap(result => result)
      );
  }

}

import { Injectable } from '@angular/core';

import { ValidationConfig } from '../models/plot-container.abstract';

@Injectable({
  providedIn: 'root'
})
export class TransformDataService {

  index(array: {}[], keyField: number | string): {} {
    // convert a unique array of objects to a single array with property names of unique 'keyField'
    return array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {})
  }

  sort(rows: {}[], sortBy: string): {}[] {
    const normalise = value => typeof value === 'string' ? value.toLowerCase() : value;
    
    return sortBy ? 
      rows.sort((a, b) => {
        [a, b] = [a, b].map(obj => normalise(obj[sortBy]));
        return (a > b) ? 1 : -1
      }) :
      rows;
  }


  validate(row: {}, validation: ValidationConfig): {} {
    const isRowInvalid = Object.keys(validation).some(key => {
      
      if(!row.hasOwnProperty(key)) { return false; }

      const config = validation[key];
      const clean = config.clean;
      const isValid = config.isValid;

      if(clean && !isValid(row[key])) {
        row[key] = clean(row);
        //console.log(`Info: updated value from ${value} to ${row[key]} (${clean})`)
      }
      
      if (!isValid(row[key])) {
        console.log(`Warning: invalid value for ${key} (reason: ${config.description}). This observation will be removed from the chart`);
        return true;
      } 
    });
      
    return isRowInvalid ? undefined : row;
  }
}

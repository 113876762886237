import { Observable, Subject } from 'rxjs';
import { OnDestroy, OnInit } from '@angular/core';
import { QueryParamGroup, QueryParamBuilder, QueryParam } from '@ngqp/core';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Validator, DataStream } from '../data-stream';

export interface ValidationConfig {
  [columnName: string]: { 
    description?: string,
    clean?: (value: any) => any,
    isValid: (value: any) => boolean 
  }
}

export abstract class PlotContainer implements OnInit, OnDestroy {

  private componentDestroyed$ = new Subject<void>();
  protected dataStreams: { [id: string]: DataStream<any> } = {};
  public dataTraces: {}[] = [];
  protected dataTraceDefinitionsMap: {} = {};
  protected dataValidation: Validator;
  public paramGroup: QueryParamGroup;
  protected paramGroupValueChanges$: Observable<any>;

  constructor(
    protected queryParamBuilder?: QueryParamBuilder,
  ) { 
    if (queryParamBuilder) {
      this.paramGroup = queryParamBuilder.group({});
      this.paramGroupValueChanges$ = this.paramGroup.valueChanges.pipe(
        debounceTime(500),
        takeUntil(this.componentDestroyed$)
      ) 
    }
  }

  registerQueryParam<T>(name: string, constructor: (name: string, paramBuilderOptions?: {}) => QueryParam<T>, urlParamName?: string, paramBuilderOptions?: {}): void {
    urlParamName = urlParamName ? urlParamName : name;
    if (!this.paramGroup.get(name)) {
      const queryParam = constructor(urlParamName, paramBuilderOptions);
      this.paramGroup.add(name, queryParam);
    }
  }

  addTrace(id: string, traceDefinition: { trace: {}, adapter: { [name: string]: (value, trace) => void} }, dataStream: DataStream<any>, name?: string): void {
    if(this.hasTrace(id)) return

    const adapter = Object.assign({}, traceDefinition.adapter);
    const metadata = Object.assign({}, dataStream.metadata);
    
    const baseTrace = Object.assign(
      { 
        id: id, 
        name: metadata && metadata[name] ? metadata[name] : id 
      }, 
      JSON.parse(JSON.stringify(traceDefinition.trace))
    );

    dataStream.stream.subscribe(row => {
      Object.keys(adapter).forEach(key => adapter[key](row[key], baseTrace));
    });

    this.dataTraces.push(baseTrace);
  }

  pushValue(key: string) {
    return (value, trace) => trace[key].push(value);
  }

  hasTrace(id: string): boolean {
    return this.dataTraces.some(trace => trace['id'] === id);
  }

  removeTrace(id: string): void {
    this.dataTraces = this.dataTraces.filter(trace => trace['id'] !== id);
  }

  toggleTrace(id: string, isSelected: boolean, name?: string): void {
    if (isSelected) {
      this.addTrace(id, this.dataTraceDefinitionsMap[id] || this.dataTraceDefinitionsMap['default'], this.dataStreams[id], name)
    } else {
      this.removeTrace(id)
    }
  }

  abstract ngOnInit(): void

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}

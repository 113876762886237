import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { map, flatMap } from 'rxjs/operators';

import { RestService } from '../models/rest-service.abstract';
import { MockDataService } from './mock-data.service';

export interface Population {
  gis_id: string,
  dz_name: string,
  year: number,
  population: number,
  population_type: string
}

export interface CommercialGrowth {
  gis_id: string,
  polygon_code: string,
  polygon_description: string,
  cal_year: number,
  population: number,
  population_type: string
}

export interface Consumption {
  gis_id: number,
  polygon_code: string,
  polygon_description: string,
  consumption_category: string,
  cal_year: number,
  cal_month_no: number,
  value: number
}

export interface Supply {
  gis_id: number,
  polygon_description: string,
  cal_year: number,
  cal_month_no: number,
  value: number
}

export interface TimeSeriesData {
  time_stamp: string,
  data_tag: string,
  value: number
}

export interface TimeSeriesMetadata {
  gis_id: string,
  data_tag: string,
  data_description: string
  units: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService extends RestService {
  baseUrl = this.apiBaseUrls.awsApiGateway;

  constructor(
    protected httpClient: HttpClient,
    private papa: Papa,
    protected mockDataService: MockDataService
    ) { super(httpClient); }

  private parseCSVError = text => { 
    throw Error(`error parsing csv: ${text}`)
  }

  getDemandbyGisId(traceId: string, queryParams: { gisid: string, polygonName: string, export?:string }): Observable<any> {
    return this.get(traceId, queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            }),
        flatMap(result => result)
      );
  }

  getDemandByPolygonCode(traceId: string, queryParams: { polygonCodes: string, polygonName: string, export?:string }): Observable<any> {
    return this.get(traceId, queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            }),
        flatMap(result => result)
      );
  }

  getTimeseriesMetadata(queryParams: { gisid: string }): Observable<any> {
    return this.get('metadata', queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
          const result = this.papa.parse(text, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true
          });
          if (result.errors.length) { this.parseCSVError(text); }
          return result.data;
        }),
        flatMap(result => result)
      );
  }

  getTimeseriesData(queryParams: { dataTags: string, startDate: string, endDate: string }): Observable<any> {
    return this.get('data', queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
              const result = this.papa.parse(text, {
                dynamicTyping: true,
                header: true,
                skipEmptyLines: true});
              if (result.errors.length) { this.parseCSVError(text); }
              return result.data;
            }),
        flatMap(result => result)
      );
  }

  getRainGaugeMetadata(): Observable<any> {
    return this.get('rainGauge', null, { responseType: 'text' })
      .pipe(
        map(text => {
          const result = this.papa.parse(text, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true});
          if (result.errors.length) { this.parseCSVError(text); }
          return result.data;
        }),
        flatMap(result => result)
      );
  }

  getNonRevenueWater(queryParams: { polygonCodes: string }): Observable<any> {
    return this.get('nonRevenueWater', queryParams, { responseType: 'text' })
      .pipe(
        map(text => {
          const result = this.papa.parse(text, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true});
          if (result.errors.length) { this.parseCSVError(text); }
          return result.data;
        }),
        flatMap(result => result)
      );
  }

  getExportUrlByGisId(traceId:string, queryParams: { gisid: string, polygonName: string, export?:string }): string {
    return this.getExportUrl(traceId, queryParams)
  }

  getExportUrlByPolygonCode(traceId:string, queryParams: { polygonCodes: string, polygonName: string, export?:string }): string {
    return this.getExportUrl(traceId, queryParams)
  }
}

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export interface Validator {
  [columnName: string]: { 
    description?: string,
    clean?: (value: any) => any,
    isValid: (value: any) => boolean 
  }
}

export class DataStream<T> {
  constructor (
    public _stream: Observable<T>,
    private validator?: Validator,
    public metadata?: {}
  ) {}

  get stream(): Observable<T> {
    return this.validator ?  
      this._stream.pipe(
        map(data => this.validate(data, this.validator)),
        filter(data => typeof data !== 'undefined')
      ) :
      this._stream
  }

  validate(data, validation: Validator): T {
    const isRowInvalid = Object.keys(validation).some(key => {
      
      if(!data.hasOwnProperty(key)) { return false; }

      const config = validation[key];
      const clean = config.clean;
      const isValid = config.isValid;

      if(clean && !isValid(data[key])) {
        data[key] = clean(data);
      }
      
      if (!isValid(data[key])) {
        console.log(`Warning: invalid value for ${key} (reason: ${config.description}). This observation will be removed from the chart`);
        return true;
      } 
    });
    return isRowInvalid ? undefined : data;
  }
}